<template>
  <article class="taycan">
    <Hero
      heading="Chargez rapidement avec Electrify Canada"
      subheading="Profitez d’un chargement CC ultrarapide pour votre Porsche Taycan"
    >
      <template #image
        ><img
          alt=""
          :src="require('@/assets/images/PorscheTaycan/19_87_957_Porsche_Landing_Page_Image_1_EN.jpg')" /></template
    ></Hero>

    <TwoColumnContentBlock heading="Charger sur la route devient plus pratique">
      <p>
        La toute première Porsche entièrement électrique est pleinement équipée pour profiter des chargeurs rapides CC
        de 350 kW du réseau Electrify Canada. Concretement, cela vous permet de recharger votre Porsche Taycan aux
        stations Electrify Canada et de passer de 5 % à 80 % d’énergie en seulement 22,5 minutes
        <reference-link num="1">{{ $t('chargingTimesMayVary') }}</reference-link>
        – la recharge la plus rapide du marché automobile.
        <reference-link num="2">En date du 30 avril 2020.</reference-link>
        Electrify Canada a choisi 32 emplacements, offrant 128 stations de recharge, dans quatre provinces canadiennes,
        et d’autres emplacements seront ajoutés. Ces emplacements se trouvent le long d’autoroutes principales et dans
        les zones métropolitaines très fréquentées.
      </p>
      <p>
        En outre, les propriétaires canadiens de Porsche Taycan sont éligibles à recevoir gratuitement, trois ans de
        sessions de recharge de 30 minutes sur le réseau de recharge de haute puissance Electrify Canada.<reference-link
          num="3"
          >Concerne les propriétaires de Porsche Taycan qui ont acheté une Porsche Taycan neuve d’un concessionnaire
          Porsche canadien autorisé. L’offre du programme s’applique aux véhicules Porsche Taycan 2020 achetés le ou
          avant le 31 décembre 2020, et est en vigueur jusqu’au 31 décembre 2023. Pour une Porsche Taycan achetée le ou
          après le 1er janvier 2021, le programme est en vigueur pour une période de trente-six (36) mois à partir de la
          date d’achat. Les premières 30 minutes de chaque recharge sont gratuites. Il doit s’écouler soixante (60)
          minutes entre les séances de recharge gratuites. Les taxes et les frais d’occupation ne sont pas compris.
          Apprenez-en plus au
          <a href="https://www.electrify-canada.ca/fr/faqs/"
            >https://www.electrify-canada.ca/fr/faqs/</a
          ></reference-link
        >
        Avec des rechargements gratuits qui sont à la fois rapides et pratiques, vous serez prêts en un rien de temps,
        pour profiter de la tenue de route et des performances incroyables de votre Porsche Taycan.
      </p>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Depuis votre téléphone">
      <template #image
        ><img
          loading="lazy"
          alt=""
          :src="require('@/assets/images/PorscheTaycan/19_87_957_Porsche_Landing_Page_Image_2_RT_FR.jpg')"
      /></template>
      <p>
        L’application Porsche Charging Service (et l’application Porsche Connect) vous permet d’accèder gratuitement à
        trois ans de recharge par session de 30 minutes sur le réseau Electrify Canada.
      </p>
      <ul>
        <li>
          <strong>Étape 1: </strong>
          <a rel="noopener" target="_blank" href="https://connect-portal.porsche.com/core/ca/en_US/home">
            Activate Porsche Connect Services</a
          >
        </li>
        <li><strong>Étape 2: </strong> S’inscrire au service de recharge Porsche</li>
        <li><strong>Étape 3: </strong> Télécharger l’application Porsche Charging Service</li>
        <li><strong>Étape 4: </strong> Localiser une borne de recharge</li>
        <li><strong>Étape 5: </strong> Brancher et démarrer votre session de chargement</li>
      </ul>
      <router-link :to="{ name: 'locate-charger-fr' }" class="button">LOCALISER UNE BORNE DE RECHARGE</router-link>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Un nouveau réseau novateur">
      <template #image>
        <img
          loading="lazy"
          alt=""
          :src="require('@/assets/images/PorscheTaycan/19_87_957_Porsche_Landing_Page_Image_3_EN.jpg')"
      /></template>
      <p>
        Faites l’expérience d’un chargement rapide grâce aux stations de chargement de 350 kW du réseau Electrify
        Canada. Ce réseau de chargeurs de haute puissance pratiques et fiables se developpe dans les régions
        métropolitaines très fréquentées et près des autoroutes achalandées du Canada. Chaque emplacement aura au moins
        un chargeur de 350 kW et des chargeurs supplémentaires produisant jusqu’à 150 kW aux véhicules compatibles.
        Grâce à leur conception fine et allongée, leur éclairage offrant une meilleure visibilité et leur système de
        refroidissement liquide novateur, le chargement rapide Electrify Canada est plus facile que jamais. Aussi, les
        deux câbles présents à chaque borne permettent de charger facilement votre véhicule électrique, quel que soit
        l’emplacement du dispositif de chargement. Découvrez le nouveau réseau Electrify Canada, mis en œuvre pour
        offrir aux Canadiens la vitesse et la fiabilité dont ils ont besoin.
      </p>
      <router-link :to="{ name: 'faqs-fr' }" class="button">EN SAVOIR PLUS</router-link><br />
    </TwoColumnContentBlock>
  </article>
</template>

<script>
import lang from '@/lang';
import ReferenceLink from '@/components/ReferenceLink.vue';
import Hero from '@/components/Hero/Hero.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';

export default {
  name: 'PorscheTaycan',
  metaInfo: {
    title: 'Programme de recharge Porsche Taycan Electrify Canada',
    meta: [
      {
        name: 'description',
        content: 'Description et renseignements du programme de recharge ultrarapide Porsche Taycan d’Electrify Canada',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-canada.ca/fr/taycan/' }],
  },
  components: { ReferenceLink, TwoColumnContentBlock, Hero },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
};
</script>
